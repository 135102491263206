.auth-header img {
  width: 50px;
  height: 50px;
}

.uk-form-icon {
  color: #3da4f4;
}

.uk-button {
  font-weight: bold;
}

.uk-button-primary {
  background-color: #3da4f4;
}

.uk-button-primary:disabled {
  background-color: #ece5d6;
  color: white;
}

.uk-button-primary:hover,
.uk-button-primary:focus,
.uk-button-primary:active {
  background-color: #57b2f6;
}

.sign-in-btn {
  background-color: transparent;
  color: #3da4f4;
  font-size: 14px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  padding: 0 30px;
}

.sign-in-btn:hover {
  color: #8bc0f4;
}

.sign-up-btn {
  background-color: #3da4f4;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  padding: 5px 30px;
  border-radius: 5px;
}

.validation {
  color: #f0506e;
  margin: 5px;
}

.service-details {
  padding: 20px 0;
  background-color: white;
  border-bottom: 1px solid #e5e5e5;
}

.service-title {
  font-size: 0.975rem;
  font-weight: 500;
  color: #030303;
}

.service-description {
  font-size: 0.875rem;
}

.documentation > * {
  border-right: 3px solid #e5e5e5;
}

.documention > *:last-child {
  border-right: none;
}

.documentation-header {
  background-color: #f5f5f5;
  height: 30px;
  padding: 10px 20px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
}

.documentation-header > span {
  font-size: 0.875rem;
  color: #030303;
  font-weight: 500;
}

.uk-label {
  color: white !important;
  font-weight: 500;
}

.uk-label-post {
  background-color: #1e87f0;
}

.uk-label-get {
  background-color: #48a875;
}

.uk-label-delete {
  background-color: #f0506e;
}

.endpoint-method {
  font-size: 18px !important;
}

.endpoint-method-get {
  color: #0b6623 !important;
}

.endpoint-method-post {
  color: #57b2f6 !important;
}

.endpoint-method-delete {
  color: #ff0000 !important;
}

.endpoint-name code {
  background-color: #f8f8ff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-left: 8px;
}

.endpoint-token-cost {
  text-transform: none;
}

.endpoint-list {
  margin-top: 0;
}

.endpoint-list li {
  padding: 15px 20px;
  cursor: pointer;
}

.endpoint-list li:hover {
  background-color: #f5f5f5;
}

.endpoint-list li.active {
  background-color: #ebebeb;
}

.endpoint-selection-name {
  font-size: 0.875rem;
  color: #030303;
}

.endpoint-container {
  overflow-y: scroll;
  height: 82vh;
}

/* width */
.endpoint-container::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.endpoint-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.endpoint-container::-webkit-scrollbar-thumb {
  background: #bcbcbc;
}

/* Handle on hover */
.endpoint-container::-webkit-scrollbar-thumb:hover {
  background: #8d8d8d;
}

.endpoint-description {
  padding: 15px 20px;
  border-bottom: 1px solid #e5e5e5;
}

.endpoint-description > p {
  font-size: 0.875rem;
}

.endpoint-content-header {
  font-size: 0.975rem;
  color: #030303 !important;
  font-weight: 500 !important;
}

.endpoint-usage .uk-accordion > :nth-child(n + 2) {
  margin-top: 0px;
}

.endpoint-usage .uk-accordion-title {
  background-color: #f5f5f5;
  padding: 10px 20px;
  color: #030303;
  font-weight: 500;
  font-size: 0.875rem;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.endpoint-usage .uk-accordion-content {
  padding: 10px 20px;
}

.endpoint-usage .uk-accordion-content .uk-form-label {
  color: #030303;
  font-size: 16px;
}

.endpoint-usage .uk-accordion-content .uk-input {
  border-radius: 5px;
}

.endpoint-usage .required {
  margin-top: 5px;
  font-size: 14px;
}

.endpoint-request-parameters {
  padding-bottom: 50px;
}

.endpoint-response-parameters {
  padding-bottom: 50px;
}

.endpoint-parameter {
  border-bottom: 1px solid #e5e5e5;
  padding: 15px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  color: #030303;
  font-size: 0.875rem;
}

.endpoint-parameter:first-child {
  padding-top: 0;
}

.endpoint-parameter-key {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.endpoint-parameter-type {
  font-size: 13px;
  font-weight: 500;
  color: #8792a2 !important;
  margin-left: 4px;
}

.endpoint-parameter-type .required {
  color: #ff0000 !important;
}

.endpoint-request-sample,
.endpoint-response-sample {
  border-radius: 5px;
}

.endpoint-sample-header,
.endpoint-request-content-header {
  font-size: 0.875rem;
  color: #030303;
  font-weight: bold;
}

.endpoint-request-content-header {
  padding-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
}

.endpoint-parameter code {
  border-radius: 5px;
  background-color: #f8f8ff;
  border: 1px solid #e5e5e5;
  color: #219161;
}

.endpoint-code-snippet {
  padding: 10px 20px;
}

.code-copy {
  color: #8792a2 !important;
  cursor: pointer;
}

.code-copy:hover {
  color: #219161 !important;
}

.documentation-main > * {
  border-right: 3px solid #e5e5e5;
}

.documentation-main > *:last-child {
  border-right: none;
}

.uk-card-pricing {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.uk-card-pricing:hover {
  box-shadow: none !important;
}

.user-menu {
  display: flex !important;
  border: none;
  background-color: transparent;
}

.user-menu:focus {
  border: none;
  background-color: transparent;
  outline: none;
}

.user-menu > * {
  text-transform: none;
}

.order-header {
  font-size: 18px;
  border-bottom: 3px solid #e5e5e5;
  padding-bottom: 5px;
}

.order-details .uk-table {
  border-top: 2px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5;
}

.order-details .uk-table thead tr th {
  font-weight: bold;
  color: #666;
}

.order-details .uk-table .striped {
  background-color: #f8f8f8;
}

.rdt_TableCol > * {
  font-size: 14px !important;
}

.rdt_TableRow > * {
  font-size: 16px !important;
}

.rdt_Pagination > * {
  font-size: 14px !important;
}
